import Renderer from '../../Renderer/renderer'
import React from "react";
import ReactDOM from "react-dom";

import dayjs from 'dayjs'

export const dim = (dimension, states) => {
  ////console.log(states);
  const platform = "web";
  const factor = 15;
  switch (platform) {
    case "android":
      return dimension;

    case "ios":
      return dimension;

    case "web":
      return `${dimension / factor}rem`;
  }
};

export const type_ = (...inputs) => {
  return typeof inputs[0]
}

export const duplicate = (element, amount, overrides, states) => {
  const duplicates = [];
  for (let i = 0; i < amount; i++) {
    if (typeof overrides[i] === "undefined") {
      duplicates.push(element);
    } else {
      duplicates.push(overrides[i]);
    }
  }
 //console.log('duplicate: ', element, amount, duplicates)
  return duplicates;
};

export const extract = (element, routes) => {
// console.log("extract: ", element, routes);
  const results = []
  routes.forEach((keys)=>{
    let extracted = element[keys[0]];
    try {
      keys.forEach((key, i_key) => {
        if (i_key > 0) {
          extracted = extracted[key];
        }
      });
  
      results.push(extracted);
    } catch (error) {
     //console.log('extract-error: ', error)
      results.push(null);
    }
  })
//console.log("extract: ", element, routes, [results]);
  return [results]
};

export const remove_nested = (element, routes) => {
  routes.forEach((keys) => {
    let extracted = element[keys[0]];
    let parent = element;

    try {
      keys.forEach((key, i_key) => {
        if (i_key > 0) {
          parent = extracted;
          extracted = extracted[key];
        }
      });

      if (Array.isArray(parent)) {
        parent.splice(keys[keys.length - 1], 1);
      } else {
        delete parent[keys[keys.length - 1]];
      }
    } catch (error) {
      // Handle error if needed
    }
  });

  return element;
};

export const interpolate = (...inputs) => {
  
  const min = inputs[0]
  const max = inputs[1]
  const current = inputs[2]
  const minOutput = inputs.length >= 6 ? inputs[3] : 0;
  const maxOutput = inputs.length >= 7 ? inputs[4] : 100;
  const interpolatedValue = ((current - min) / (max - min)) * (maxOutput - minOutput) + minOutput;
  
  return interpolatedValue;
}

export const pack_into = (pack_list, element, routes) => {
  pack_list.forEach((pack_item, index) => {
    let current_element = element;
    const current_route = routes[index];

    current_route.forEach((route_key, i) => {
      if (i === current_route.length - 1) {
        current_element[route_key] = pack_item;
      } else {
        if (typeof route_key === 'string') {
          if (!current_element.hasOwnProperty(route_key)) {
            current_element[route_key] = {};
          }
          current_element = current_element[route_key];
        } else if (typeof route_key === 'number') {
          if (!isArray(current_element) && (!isPlainObject(current_element) || (isPlainObject(current_element) && Object.keys(current_element).length == 0))) {
            current_element = [];
          }
          if (current_element[route_key] == null) {
            current_element[route_key] = {};
          }
          current_element = current_element[route_key];
        }
      }
    });
  });
  return element;
};


export const pack = (...inputs) => {
  let pack_list = inputs[0]
  let element = inputs[1]
  let routes = inputs[2]
  const replace = inputs.length > 6 ? inputs[3] : false

  // console.log("pre pack: ", cloneDeep(pack_list), cloneDeep(element), cloneDeep(routes), replace);
  pack_list.forEach((to_pack, i_pack) => {
    let keys = routes[i_pack]
    keys = [...keys].reverse();
    try {
      let current_obj = {};
      let pack_obj = current_obj;
      keys.forEach((key, i_key) => {
        if (i_key == 0) {
          current_obj[key] = to_pack;
        } else {
          current_obj[key] = { ...current_obj };
          delete current_obj[keys[i_key - 1]];
        }
      });
      // console.log("pack_obj: ", pack_obj)
      element = cloneDeep(overwrite(element, pack_obj, replace, null, null, null));
    } catch (e){
      // console.log("ERROR-PACK: ", pack_list, element, routes)
      console.error(e, e.stack);
      return null;
    }
  })
  // console.log("pack: ", pack_list, element, routes);
  return element
};

export const add_item = (...inputs) => {
  const item = inputs[0]
  const array = cloneDeep(inputs[1])
  const no_duplicates = inputs.length >= 6 ? inputs[2] : false

  let index = isItemInArray(item, array);
  if(index == -1){
    array.push(item)
    // console.log("add_item: ", item, array, no_duplicates)
    return array
  } else {
    if (!no_duplicates) {
      array.push(item)
      // console.log("add_item: ", item, array, no_duplicates)
      return array
    } else {
      // console.log("add_item: no duplicates allowed")
      // console.log("add_item: ", item, array, no_duplicates)
      return array
    }
  }
}

export const deepEqual = (...inputs) => {
  const x = inputs[0]
  const y = inputs[1]
  if (x === y) {
    return true;
  } else if (
    typeof x == "object" &&
    x != null &&
    typeof y == "object" &&
    y != null
  ) {
    if (Object.keys(x).length != Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else if (isArray(x) && isArray(y)) {
    if ( x.length != y.length ) return false;

    x.forEach((x_value, i_x) => {
      if ( y[i_x] ) {
        if( !deepEqual(x[i_x], y[i_x])) return false;
      } else return false;
    })

    return true;
  } else return false;
};

export const isItemInArray = (item, array) => {
  for (var i = 0; i < array.length; i++) {
      // This if statement depends on the format of your array
      if (deepEqual(array[i], item)) {
          return i;   // Found it
      }
  }
  return -1;   // Not found
}

export const remove_item = (...inputs) => { 
  const item = inputs[0]
  const array = cloneDeep(inputs[1])
  const no_duplicates = inputs.length >= 6 ? inputs[2] : false
 //console.log("remove_item: ", item, array)
  let index = isItemInArray(item, array);
  if (index != -1) {
    array.splice(index, 1);
   //console.log("remove_item: ", item, array)
    return array
  } else {
   //console.log("remove_item: item not in array")
    return array;
  }
}

export const join_string = (...inputs) => {
  const strings = inputs[0]
  const char = inputs.length >= 5 ? inputs[1] : ''

  return strings.join(char);
}

export const isPlainObject = (input) => {
  return input && !Array.isArray(input) && typeof input === "object";
};

export const isArray = (input) => {
  return Array.isArray(input);
};

export const isNumber = (...inputs) => {
  const value = inputs[0]
  const allow_strings = inputs.length >= 5 ? inputs[1] : false

  // First, check if the type is 'number' and it is not NaN, this covers integers and floats
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return true;
  }
  
  // Then, try to coerce the value to a number and check if it results in a number
  // This covers cases where the value is a number in a string, like "123" or "45.67"
  if (allow_strings && typeof value === 'string' && !Number.isNaN(+value)) {
    return true;
  }
  
  // If neither condition is true, then the value is not any kind of number
  return false;
};

export const isBoolean = (...inputs) => {
  const value = inputs[0]
  const allow_strings = inputs.length >= 5 ? inputs[1] : false

  // Directly check for true or false booleans
  if (typeof value === 'boolean') {
    return true;
  }
  
  // Check for "true" or "false" strings
  if (allow_strings && (value === "true" || value === "false")) {
    return true;
  }
  
  // If neither, then it's not any kind of boolean
  return false;
};

export const isString = (...inputs) => {
  const value = inputs[0]
  
  // Check if the type is 'string' for primitive strings
  if (typeof value === 'string') {
    return true;
  }
  
  // Check if it's an instance of the String object
  if (value instanceof String) {
    return true;
  }
  
  // If neither, then it's not any kind of string
  return false;
};

export const parameters = (object) => {
  return isPlainObject(object) ? Objects.keys(object) : object.keys();
};

export const map = (object, overrides) => {
  const obj_maps = [];
  overrides.forEach((override, i_over) => {
    if (isPlainObject(object)) {
      const map_object = { ...object, ...override };
      obj_maps.push(map_object);
    }

    if (isArray(object)) {
      const map_object = [...object, ...override];
      obj_maps.push(map_object);
    }
  });
};

// export const date = (mode, parameters) => {

// }

export const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

const log_array = []

const now = (...inputs) => {
  const format = inputs.length > 3 ? inputs[0] : 'YYYY-MM-DD'
  return dayjs().format(format)
}

export const log = (...inputs) => {
  const to_log = inputs[0] 
  const clone = inputs.length >= 5 ? inputs[1] : true
  const states = inputs[inputs.length-3]
  const process_inputs = inputs[inputs.length-2]
  if(clone===true){
    console.log("[tWLC-LOG]:", cloneDeep(to_log));
  }else{
    console.log("[tWLC-LOG]:", to_log);
  }
  if (log_array.length < 150) {
    log_array.push(JSON.stringify({date: now(), log: to_log}))
  }else{
    log_array.push(JSON.stringify({date: now(), log: to_log}))
    log_array.shift()
  }
  return to_log;
};

export const getLog = (...inputs) => {
  return log_array;
};

export const block = (input_list, state, inputs, events) => {
 //console.log("blocked...");
};

// export const text = (object) => {
//   const object_text = JSON.stringify(object, null, 4);
//   return object_text;
// };

export const text = (object) => {
  if (typeof object === 'object' && object !== null) {
    // If it's an object (excluding null), stringify it with indentation
    return JSON.stringify(object, null, 4);
  } else if (typeof object === 'number' && !isNaN(object) && isFinite(object)) {
    // If it's a number, convert it to a string directly
    return object.toString();
  } else {
    // For other types, stringify using the default method
    return String(object);
  }
};

export const keys = (...inputs) => {
  const object = inputs[0]
  if (isPlainObject(object)) {
    return Object.keys(object);
  } else {
    return null;
  }
};

export const entries = (...inputs) => {
  const object = inputs[0]
  if (isPlainObject(object)) {
    return Object.entries(object);
  } else {
    return null;
  }
};

export const from_entries = (...inputs) => {
  const entries = inputs[0]
  try {
    return Object.fromEntries(entries);
  } catch {
    console.log('from_entries failed')
    return null;
  }
};

export const values = (...inputs) => {
  const object = inputs[0]
  if (isPlainObject(object)) {
    return Object.values(object);
  } else {
    return null;
  }
};

export const length_of = (array) => {
  //console.log("length: ", array.length, isArray(array));
   if (isArray(array) || typeof array == 'string') {
     return array.length;
   } else {
     return null;
   }
 };

export const ifThis = (if_statement, this_object, else_object) => {
  if (if_statement) {
    return this_object;
  } else {
    return else_object;
  }
};

export const merge = (...inputs) => {
  const inputs_1 = inputs[0];
  const inputs_2 = inputs[1];

  const obj_1 = Object.assign({}, inputs_1);
  const obj_2 = Object.assign({}, inputs_2);

  const merge = { ...obj_1, ...obj_2 };

  return Object.values(merge);
};

export const mergeList = (...inputs) => {
  const inputs_1 = inputs[0];
  const inputs_2 = inputs[1];

  const merged = inputs_1.concat(inputs_2);

  return merged;
};

export const objectToList = (...inputs) => {
  return Object.values(inputs[0]);
};

export const listToObject = (...inputs) => {
  const list_conv = inputs[0];
  const prefix = inputs[1];
  if (prefix) {
    let list_obj = {};
    list_conv.forEach((item, i_item) => {
      list_obj[`${prefix}-${i_item}`] = item;
    });
    return list_obj;
  } else {
    return Object.assign({}, inputs[0]);
  }
};

export const wrapArray = (...inputs) => {
  return [inputs.slice(0, inputs.length-3)]
}

export const renderer = (...inputs) => {
  const comp_config = inputs[0];
  const comp_state = inputs[1];
  const comp_inputs = inputs[2];
  const comp_events = inputs[3];
  const comp_name = inputs[4];

  return {
    name: comp_name,
    type: "component",
    config: comp_config,
    state: comp_state,
    inputs: comp_inputs,
    events: comp_events
  };
};

export const isFunction = value => value && (Object.prototype.toString.call(value) === "[object Function]" || "function" === typeof value || value instanceof Function);

export const cloneDeep = (obj) => {
  if ( obj && typeof obj !== "undefined" && obj.skip_cloneDeep ) {
    return obj
  }
  // let clonedObjStr = JSON.stringify(obj, function (key, value) {
  //   return typeof value === "function" ? value.toString() : value;
  // });

  // let clonedObj = JSON.parse(clonedObjStr, function (key, value) {
  //   if (typeof value != "string") return value;
  //   return value.substring(0, 8) == "function"
  //     ? eval("(" + value + ")")
  //     : value;
  // });

  // return clonedObj;
  if ( isPlainObject(obj) ){
    const cloned_obj = {}
    Object.keys(obj).forEach((obj_key) => {
      cloned_obj[obj_key] = cloneDeep(obj[obj_key])
    });
    return cloned_obj
  }

  if ( isArray(obj) ){
    const cloned_obj = []
    obj.forEach((obj_value, i_value) => {
      cloned_obj[i_value] = cloneDeep(obj_value)
    });
    return cloned_obj
  }

  if ( isFunction(obj) ){
    return obj
  }

  return obj
};

export const cloneObject = (...inputs) => {
  const obj = inputs[0];
  return cloneDeep(obj);
};

export const overwrite = (...inputs) => {
  const base_obj = inputs[0];
  const overwrite_obj = inputs[1];
  const replace = inputs.length > 5 ? inputs[2] : false
  // console.log("overwrite inputs: ", inputs)

  if (isPlainObject(overwrite_obj) && isPlainObject(base_obj)) {
    Object.entries(overwrite_obj).forEach(([key, value]) => {
      if (Object.keys(base_obj).includes(key)) {
        if (
          ((isPlainObject(value) && isPlainObject(base_obj[key])) ||
          (isArray(value) && isArray(base_obj[key]))) && !replace
        ) {
          // console.log("overwriting in pack: ", base_obj[key], value, replace, overwrite(base_obj[key], value, replace, null, null, null))
          base_obj[key] = overwrite(base_obj[key], value, replace, null, null, null);
        } else {
          base_obj[key] = value;
        }
      } else {
        base_obj[key] = value;
      }
    });
   //console.log(
    //   "OVERWRITE: ",
    //   cloneDeep(base_obj),
    //   overwrite_obj
    // );
    return base_obj;
  } else {
    if (isArray(overwrite_obj) && isArray(base_obj)) {
      overwrite_obj.forEach((value, key) => {
        if (base_obj[key] || true) {
          if (
            ((isPlainObject(value) && isPlainObject(base_obj[key])) ||
            (isArray(value) && isArray(base_obj[key]))) && !replace
          ) {
            // console.log('overwriting in overwrite array: ', base_obj[key], value, overwrite(base_obj[key], value, replace, null, null, null))
            base_obj[key] = overwrite(base_obj[key], value, replace, null, null, null);
          } else {
            // console.log('not overwriting in overwrite array: ', base_obj[key], value, replace)
            base_obj[key] = value;
          }
        }
        // console.log('nothing in overwrite: ', base_obj[key], value)
      });
     //console.log(
      //   "OVERWRITE: ",
      //   cloneDeep(base_obj),
      //   overwrite_obj
      // );
      return base_obj;
    } else {
      // base_obj = overwrite_obj
     //console.log(
      //   "OVERWRITE: ",
      //   cloneDeep(base_obj),
      //   overwrite_obj
      // );
      return overwrite_obj;
    }
  }
};

export const includes_item = (...inputs) => {
  const check_item = inputs[0]
  const array = inputs[1]

  return array.includes(check_item)
}

export const loose_match = (...inputs) => {
  const str1 = inputs[0]
  const str2 = inputs[1]
  const operation = inputs.length >= 6 ? inputs[2] : "or"

  const key_words = str1.split(' ')

  let result = null
  if (operation == "and"){
    result = true
  }
  if (operation == "or"){
    result = false
  }

  key_words.forEach((word)=>{
    if (operation == "and"){
      result = result && str2.includes(word) 
    }
    if (operation == "or"){
      result = result || str2.includes(word) 
    }
  })
  return result
}

export const transformObj = (...inputs) => {
  const to_transf = inputs[0]
  const transform_config = inputs[1]

  if (isPlainObject(transform_config)){
    Object.keys(transform_config).forEach((tc_key) => {
      transform_config[tc_key] = transformObj(to_transf, transform_config[tc_key])
    })
   //console.log('transformObj: ', cloneDeep(inputs))
    // return cloneDeep(transform_config)
    return transform_config
  } else {
    if (isArray(transform_config)) {
      transform_config.forEach((_, tc_i) => {
        transform_config[tc_i] = transformObj(to_transf, transform_config[tc_i])
      })
     //console.log('transformObj: ', cloneDeep(inputs))
      // return cloneDeep(transform_config)
      return transform_config
    } else {
      const route = String(transform_config).split("/")
      const inject_value = extract(to_transf, [route])
     //console.log('transformObj: ', cloneDeep(inputs), route, inject_value)
      return inject_value[0][0]
    }
  }
}

export const formatStr = (...inputs) => {
  const values = inputs[0]
  const to_format = inputs[1]

  return to_format.format(...values)
}

function containsHostname(url) {
  const pattern = /^(https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}(:\d+)?|^localhost(:\d+)?$|^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(:\d+)?$/;
  return pattern.test(url);
}

function getTypedURLParams(url) {
  const url_split = url.split('?')
  if(url_split.length < 2){
    return {}
  }
  const extracted_search = '?'+url_split[1]
  const params = new URLSearchParams(extracted_search);
  const paramsJSON = {};

  params.forEach((value, key) => {
      // Attempt to infer and convert data types
      if (!isNaN(value) && value.trim() !== '') {
          // Convert to number if it's a valid number
          paramsJSON[key] = Number(value);
      } else if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
          // Convert to boolean if it's 'true' or 'false'
          paramsJSON[key] = value.toLowerCase() === 'true';
      } else {
          // Keep as string in all other cases
          paramsJSON[key] = value;
      }
  });

  return paramsJSON;
}

function extractIdsFromUrl(url) {
  // Split the URL by '/' and remove empty elements
  let parts = url.split('/')
  parts = parts.map(part => part.split('?')[0])
  parts = parts.filter(part => part.length > 0);

  let appId, componentId;

  // Check the number of parts in the URL
  if (parts.length === 2) {
      // If there are two parts, assume the first is app_id and the second is component_id
      [appId, componentId] = parts;
  } else if (parts.length === 1) {
      // If there is only one part, assume it's component_id and use the default value for app_id
      appId = window.twlc_current_app;
      [componentId] = parts;
  } else {
      // If the URL format doesn't match the expected patterns
      return { error: "Invalid URL format" };
  }

  // Optionally, you could also parse query parameters here if needed

  return { appId, componentId };
}

export const goTo = (...inputs) => {
  const new_location = inputs[0]
  const stateObj = inputs.length >=5 ? inputs[1] : {}
  const renderer_node = document.getElementById('renderer')
  if (containsHostname(new_location) || !renderer_node){
    window.location.assign(new_location)
  }else{
    let component_inputs;
    if(Object.keys(stateObj).length==0){
      component_inputs = getTypedURLParams(new_location)
    }else{
      component_inputs = stateObj;
    }
    const { appId, componentId } = extractIdsFromUrl(new_location)
    window.twlc_current_app = appId
    history.pushState({app: appId, component:componentId, component_inputs:component_inputs, node:'renderer'}, '', new_location);
    ReactDOM.render(<Renderer app_id={appId} component_id={componentId} component_inputs={component_inputs}/>, renderer_node);
  }
}

export const goBack = () => {
  // Check if it's possible to go back
  if (window.history.length > 1) {
    console.log(window.history)
    window.history.back();
  } else {
    // Optionally, handle the case where there's no history to go back to
    console.log("No history to go back to.");
    // You could redirect to a default location or perform some other action
    // window.location.href = 'your_default_location_here';
  }
}

export const merge_objects = (...inputs) => {
  // Create a new object to store the merged values
  const obj1 = inputs[0]
  const obj2 = inputs[1]
  const merged = {};

  // Iterate over all keys in obj1
  for (let key in obj1) {
    // Check if the key exists in obj2
    if (obj2.hasOwnProperty(key)) {
      // Check if the value is an object or array
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        // Recursively merge nested objects or arrays
        merged[key] = merge_objects(obj1[key], obj2[key]);
      } else {
        // Otherwise, use the value from obj2
        merged[key] = obj2[key];
      }
    } else {
      // If the key doesn't exist in obj2, use the value from obj1
      merged[key] = obj1[key];
    }
  }

  // Iterate over all keys in obj2
  for (let key in obj2) {
    // Check if the key exists in obj1
    if (!obj1.hasOwnProperty(key)) {
      // If the key doesn't exist in obj1, use the value from obj2
      merged[key] = obj2[key];
    }
  }

  // Return the merged object
  return merged;
}

export const sliceObj = (...inputs) => {
  const obj = inputs[0]
  const sliceIndices = inputs[1]
  
  if (typeof obj === 'string') {
    return obj.slice(...sliceIndices);
  } else if (Array.isArray(obj)) {
    return obj.slice(...sliceIndices);
  } else {
    throw new Error('Input must be a string or an array');
  }
}

export const lowercase = (...inputs) => {
  const to_convert = inputs[0]
  return to_convert.toLowerCase()
}

export const uppercase = (...inputs) => {
  const to_convert = inputs[0]
  return to_convert.toUpperCase()
}

export const capitalize = (...inputs) => {
  const to_convert = inputs[0]
  return to_convert.toLowerCase().split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
}

export const filter_objects = (...inputs) => {
  const lst = inputs[0];
  const filterFunc = inputs[1];
  // console.log("filter_objects", lst, filterFunc);
  return lst.filter((element, index, array) => {return filterFunc(element)});
}

export const sort_objects = (...inputs) => {
  const objList = inputs[0]
  const sortFunc = inputs[1]
  const clone = inputs.length >= 6 ? inputs[2] : true

  if (clone) {
    return cloneDeep(objList).sort((a, b) => {
      const result = sortFunc(a,b)
      // console.log("sort_objects", objList, a, b, result)
      return result
    });
  }else{
    return objList.sort((a, b) => {
      const result = sortFunc(a,b)
      // console.log("sort_objects", objList, a, b, result)
      return result
    });
  }
}

export const split_string = (...inputs) => {
  const to_split = inputs[0];
  const split_char = inputs[1];
  return to_split.split(split_char);
}

export const clipboardCopy = (...inputs) => {
  const text = inputs[0];
  navigator.clipboard.writeText(text);
  return text
}

export const pass_ = (...inputs) => {
  return inputs.length > 3 ? inputs[0] : null
}

export const speakText = (...inputs) => {
  const text = inputs[0]
  if ('speechSynthesis' in window) {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  } else {
    console.error('Web Speech API is not supported in this browser.');
  }
}

export const searchNested = (...inputs) => {
  const obj = inputs[0]
  const inputFunction = inputs[1]
  const currentRoute = inputs.length >= 6 ? inputs[2] : ''
  const matches = inputs.length >= 7 ? inputs[3] : []

  if (typeof obj !== 'object' || obj === null) {
    return matches;
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const newRoute = currentRoute ? `${currentRoute}/${i}` : `${i}`;
      searchNested(obj[i], inputFunction, newRoute, matches, null, null, null);
    }
  } else {
    for (const key in obj) {
      const value = obj[key];
      const newRoute = currentRoute ? `${currentRoute}/${key}` : `${key}`;

      if (inputFunction(value)) {
        matches.push({ route: newRoute, match: value });
      }

      searchNested(value, inputFunction, newRoute, matches, null, null, null);
    }
  }

  return matches;
}

import anime from 'animejs/lib/anime.es.js';

export const animate = (...inputs) => {
  const animation_config = inputs[0]

  anime({...animation_config})
}


export const set_timeout = (...inputs) => {
  const func = inputs[0]
  const delay = inputs[1]

  return setTimeout(func, delay)
}

export const clear_timeout = (...inputs) => {
  const id_num = inputs[0]

  return clearTimeout(id_num)
}

export const set_interval = (...inputs) => {
  const func = inputs[0]
  const delay = inputs[1]

  return setInterval(func, delay)
}

export const clear_interval = (...inputs) => {
  const id_num = inputs[0]

  return clearInterval(id_num)
}

export const is_null = (...inputs) => {
  const to_check = inputs[0]

  if (to_check == null){
    return true
  }
}

export const to_number = (...inputs) => {
  const str_num = inputs[0]
  const type = inputs.length >=5 ? inputs[1] : "integer"

  if (type=="integer"){
    return parseInt(str_num)
  }

  if (type=="float"){
    return parseFloat(str_num)
  }
}

export const to_phonenumber = (...inputs) => {
  const number = inputs[0]
  // Convert the number to a string
  let numStr = number.toString();

  // Extract the country code (assuming it's the first two digits)
  let countryCode = `+${numStr.slice(0, 2)}`;
  
  // Remove the country code from the number string
  numStr = numStr.slice(2);

  // Determine the format based on the remaining number length
  if (numStr.length === 10) {
      // Assuming it's a cellphone number in the format 8138000238
      return `${countryCode} ${numStr.slice(0, 3)} ${numStr.slice(3, 6)} ${numStr.slice(6)}`;
  } else if (numStr.length === 8) {
      // Assuming it's a landline number in the format 81107725
      return `${countryCode} ${numStr.slice(0, 2)} ${numStr.slice(2, 5)} ${numStr.slice(5)}`;
  } else {
      // For other formats, just return the original number
      return number;
  }
}